import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import './QuickTopupSuccess.css';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import html2pdf from 'html2pdf.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdShare } from 'react-icons/io';
import { GiSaveArrow } from 'react-icons/gi';
import vw from '../../../images/redesignLogo2.jpg';
import moment from 'moment';

const QuickTopSuccess = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const currentDate = moment().format('MMM Do, YYYY HH:mm:ss A');

  const { amount, MSISDN, subscriberInfo, paystackPaymentReference } = location.state || {};

  useEffect(() => {
    document.title = amount ? `Vitel Wireless | ${amount} Airtime top up success` : 'Airtime top up success';
  }, [amount]);
  //   console.log('topupinfo', topupinfo);

  const generatePDF = () => {
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${paystackPaymentReference}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    const content = document.getElementById('pdfContent');

    html2pdf().set(options).from(content).save();
  };

  const generateAndSharePDF = async () => {
    const element = document.getElementById('pdfContent');
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${paystackPaymentReference}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    try {
      // Generate PDF as Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');

      // Convert Blob to File
      const pdfFile = new File([pdfBlob], `VW_${paystackPaymentReference}.pdf`, {
        type: 'application/pdf',
      });

      // Check if Web Share API is supported
      if (navigation.canShare && navigation.canShare({ files: [pdfFile] })) {
        // Use Web Share API
        await navigation.share({
          title: `VW_${paystackPaymentReference}.pdf`,
          text: 'Check out this PDF file!',
          files: [pdfFile], // Pass the file for sharing
        });

        // console.log('Shared successfully');
      } else {
        // Fallback for unsupported browsers
        alert('Sharing not supported on this browser; the file will be downloaded automatically');
      }
    } catch (error) {
      console.error('Error generating or sharing the PDF:', error);
    }
  };

  const handleBackToHome = () => {
    navigation('/');
  };

  return (
    <>
    <div className="parentTopupreceipt">
      <div className="topupreceiptdiv" id="pdfContent">
        <div className="recieptFirstItem">
          <div className="network">
            <img src={vw} alt="" />
          </div>
          <h3>
            ₦
            {parseInt(amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
          <span className="topupstatus">
            <IoMdCheckmarkCircle className="topupIcon" /> Airtime top up successful
          </span>
          <div className="transBonusdiv">
            <span>Bonus Earned</span> <span>₦0</span>
          </div>
        </div>

        <div className="topuptransacdetial">
          <h4>Transaction Details</h4>
          <div className="parenttransacDiv">
            <div className="transdiv">
              <span>Recipient Mobile</span> <span>{MSISDN.replace(/^234/, '0')}</span>
            </div>
            <div className="transdiv">
              <span>Recipient Name</span>{' '}
              <span>
                {subscriberInfo.lastName} {subscriberInfo.firstName}
              </span>
            </div>
            <div className="transdiv">
              <span>Transaction Type</span> <span>Airtime top up</span>
            </div>

            <div className="transdiv">
              <span>Transaction Ref.</span> <span>{paystackPaymentReference}</span>
            </div>
            <div className="transdiv">
              <span>Transaction Date</span> <span>{currentDate} </span>
            </div>
          </div>
        </div>

        <div className="backToHome">
          <div className="saveIconDiv" onClick={generatePDF}>
            <span className="shareIconspan">
              <GiSaveArrow className="saveIcon" />
            </span>
            <span>Download Receipt</span>
          </div>
          <div className="shareIconDiv saveIconDiv2" onClick={generateAndSharePDF}>
            <span className="shareIconspan">
              <IoMdShare className="saveIcon" />
            </span>
            <span>Share Receipt</span>
          </div>

          <button className="getBackBtn" onClick={handleBackToHome}>
            Back To Home
          </button>
        </div>
      </div>
    
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    
    </>
  );
};

export default QuickTopSuccess;
