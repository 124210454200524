import React, { useState, useEffect } from 'react';

import topUpImg from '../../images/mobileview.jpg';
import { PaystackButton } from 'react-paystack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { vitelWirelessAxios } from '../../utility/axios';
import './AirTimeTopConfirmsecond.css';
import useFectch from '../../utility/getFunction';
import { Button, Modal } from 'react-bootstrap';

const AirTimeTopConfirm = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { amount, MSISDN, subscriberInfo } = location.state || {};
  // const [allowRecharge, setAllowRecharge] = useState(false);
  const [airTimeError, setAirtimeErro] = useState(false);
  const [payReference, setPayReference] = useState();
  const [pendingWalletRecharge, setPendingWalletRecharge] = useState(false);
  const [creditType, setCreditType] = useState('');
  const [showLessBalance, setShowLessBalance] = useState(false);
  const [showLessMoney, setShowLessMoney] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [ShowRecharge, setShowRecharge] = useState(false);
  var airTimeTopUpObj;

  const userNumberDisplay = localStorage.getItem('MSISDN');
  const subscribInfoRaw = localStorage.getItem('subscriberInformation');
  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
  const [walletData, setWalletData] = useState('');
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [isPendingData, setIsPendingData] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = {
      userId: subscribInfoProcessed?.customerId,
      phoneNumber: userNumberDisplay,
    };

    setIsPending(true);
    vitelWirelessAxios
      .post('/payments/getWalletLedgerData', getData)
      .then(response => {
        if (!response.statusText === 'OK') {
          throw Error('Could not fetch data');
        } else {
          setIsPending(false);
        }
        setIsPending(false);
        setWalletData(response.data);
        // setData(response.data)

        setError(null);
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message);
        setIsPending(false);
        console.log(error);
      });
  }, []);

  const handleSetShowRecharge = () => {
    setShowRecharge(true);
  };
  const handleHideShowRecharge = () => {
    setShowRecharge(false);
  };

  // const { data: walletData, isPending: walletPending, error: walletError } = useFectch(`/payments/getWalletLedgerData/${subscribInfoProcessed?.customerId}`);

  console.log('subscribInfoProcessed', subscribInfoProcessed);

  useEffect(() => {
    document.title = amount ? `Vitel Wireless | ${amount} Airtime top up` : 'Airtime top up';
  }, [amount]);

  const handleNavigateAccount = () => {
    if (subscribInfoRaw) {
      navigation('/customer/account/subscriber');
    } else {
      navigation('/subscriber-login');
    }
  };

  const CustomerLedger = walletData?.lastCustomerLedger;

  const handleShowLessMoney = () => {
    setShowLessMoney(true);
  };
  const handleHideLessMoney = () => {
    setShowLessMoney(false);
  };
  const handlehideLogin = () => {
    setShowLogin(false);
  };

  const handleCheckBalance = data => {
    handleHideShowRecharge();
    if (subscribInfoRaw && CustomerLedger) {
      if (CustomerLedger?.currentBalance < amount) {
        setShowLessMoney(true);
      } else {
        handleSubmitWalletRecharge();
      }
    } else if (subscribInfoRaw && !CustomerLedger) {
      setShowLessMoney(true);
    } else {
      setShowLogin(true);
    }
  };

  // useEffect(()=>{
  //   handleCreditType()
  // },[creditType])

  // console.log("subscriberInfo", subscriberInfo);

  const handSubscriberInfo = reference => {
    setPayReference(reference.reference);
    const payStackObj = {
      MSISDN: MSISDN,
      productId: 'ZK' + amount,
      amount: amount,
      userName: MSISDN,
      paystackPaymentReference: reference.reference,
    };

    airTimeTopUpObj = payStackObj;
  };
  console.log('sendRecord>>>>>', CustomerLedger?.currentBalance);

  const handleSubmitWalletRecharge = async data => {
    setIsPendingData(true);
    setPendingWalletRecharge(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'debit',
      amount: +amount,
      phoneNumber: subscribInfoProcessed?.phoneNumber,
      paidFor: 'rechargeCard',
      creditType: 'vitelWallet',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: CustomerLedger?.walletId,
    };

    console.log('walletSendDataNOOO >>>>>', walletSendData);

    await vitelWirelessAxios
      .post('/payments/createWalletLedger', walletSendData)
      .then(res => {
        console.log('RechargeReturn', res);
        setPendingWalletRecharge(false);
        let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
        myModal.show();
        document.getElementById('modalCloseButton').addEventListener('click', () => {
          handleNavigateSuccess(amount, MSISDN, subscriberInfo);
        });
      })
      .catch(err => {
        console.log('err', err);
        setPendingWalletRecharge(false);
      });
  };

  const handlePaystackSuccessAction = async reference => {
    handSubscriberInfo(reference);
    // setLoadingButton(true);
    // console.log('allValue', airTimeTopUpObj);
    const payStackRef = airTimeTopUpObj.paystackPaymentReference;
    await vitelWirelessAxios
      .post('/generals/handleTopUpCredit', airTimeTopUpObj)
      .then(res => {
        console.log('success topup message', res);
        let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
        myModal.show();
        document.getElementById('modalCloseButton').addEventListener('click', () => {
          handleNavigateSuccess(amount, MSISDN, subscriberInfo, payStackRef);
        });
      })
      .catch(err => {
        setAirtimeErro(true);
        console.log('top up error');
      });
  };

  const handleCancel = () => {
    let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
    myModal.hide();
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // setEmail('')
    //allValue = " ";
  };

  //

  const config = {
    reference: new Date().getTime().toString(),
    email: subscriberInfo?.emailAddress,
    amount: amount * 100,
    // currency: currencyCode,
    publicKey: 'pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc',

    // publicKey: "pk_live_b3304b8274925a6dd5479dc6972a3ce6adde2396",
  };

  const componentProps = {
    ...config,
    text: 'Recharge With Bank',
    onSuccess: reference => {
      handlePaystackSuccessAction(reference);
    },
    onClose: handlePaystackCloseAction,
  };

  const handleNavigateSuccess = (amount, MSISDN, subscriberInfo, payReference) => {
    navigation(`/quick-topup-confirmation`, {
      state: {
        amount: amount,
        MSISDN: MSISDN,
        subscriberInfo: subscriberInfo,
        paystackPaymentReference: payReference,
      },
    });
  };

  return (
    <div className="parentAirtimeTopup">
      <div className="secondLayerTopUp">
        <div className="topupIMGDiv">
          <img src={topUpImg} alt="" />
        </div>
        <div className="topUpMainDiv">
          <h3>Air time top up request details</h3>
          <table className="table ">
            <thead></thead>
            <tbody>
              <tr>
                <th scope="row">Full Name</th>
                <td>
                  {subscriberInfo?.lastName} {subscriberInfo?.firstName}
                </td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{subscriberInfo?.emailAddress}</td>
              </tr>
              <tr>
                <th scope="row">Phone Number</th>
                <td>{MSISDN}</td>
              </tr>
              <tr>
                <th scope="row">Amount</th>
                <td colspan="2">{amount}</td>
              </tr>
              <tr>
                <th scope="row">Discount</th>
                <td colspan="2">0</td>
              </tr>
              <tr>
                <th scope="row">Total</th>
                <td colspan="2">{amount}</td>
              </tr>
            </tbody>
          </table>
          <div className="setDivSENdtop">
            <div className="payNowDiv">
              <button className="getStartedBtn rechargewithVite" onClick={handleSetShowRecharge}>
                {' '}
                Recharge with Vitel Wallet
              </button>
            </div>
            <div className="payNowDiv">
              <PaystackButton
                className="getStartedBtn"
                {...componentProps}
                // disabled={isFieldsEmpty()}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Airtime Top up Successful
              </h1>
            </div>
            {airTimeError ? (
              <div className="modal-body">
                Your airtime top-up of <span>₦ {amount}</span> to <span className="errorMessage">{MSISDN}</span> was not
                successful.
              </div>
            ) : (
              <div className="modal-body">
                Your airtime top-up of ₦ {amount} to <span className="errorMessage">{MSISDN}</span> was successful.
              </div>
            )}

            <div className="modal-footer">
              <button
                onClick={() => handleNavigateSuccess(amount, MSISDN, subscriberInfo, payReference)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                id="modalCloseButton"
              >
                View Receipt
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="modalCloseButton"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ShowRecharge}
        onHide={handleHideShowRecharge}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: 'center', fontWeight: 'bold' }}>Pay Via Vitel Wireless Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The amount for this transaction will be deducted from your Vitel Wireless wallet balance. Are you sure you
            want to proceed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideShowRecharge}>
            Cancel
          </Button>

          {isPendingData ? (
            <Button className="WProceedBtn">
              <i className=""></i>
              <span class="loaderButton" id="searcMob-btn"></span> Loading...
            </Button>
          ) : (
            <Button
              className="WProceedBtn"
              onClick={() => {
                handleCheckBalance();
              }}
            >
              Proceed
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLessMoney}
        onHide={handleHideLessMoney}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="headBOsttap insTred">Insufficient balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your wallet balance is insufficient for the requested amount. Please add funds to your wallet and try again.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideLessMoney}>
            Cancel
          </Button>

          <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Fund Wallet
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLogin}
        onHide={handlehideLogin}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold' }}>Request Login </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You need to log in to use the wallet. Please log in and try again.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlehideLogin}>
            Cancel
          </Button>
          <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AirTimeTopConfirm;
